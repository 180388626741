import './App.css';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ceil } from 'mathjs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import pizza from './pizza.png';


function PizzaIcon() {
  return (
    <img src={pizza} alt="pizza" width={22} height={28} />
  );
}

function Math() {
  const [numSmallAppetites, setSmallAppetites] = useState(1);
  const [numMedAppetites, setMedAppetites] = useState(1);
  const [numBigAppetites, setBigAppetites] = useState(1);
  const [pizzaDiameter, setPizzaDiameter] = useState(12);

  function calculateNecessaryPizzas() {
    const twelveInPizzaAreaSqInches = 113;
    // What percentage of a 12" pizza each appetite would eat
    const smallAppetitePizzaPct = .3;
    const medAppetitePizzaPct = .5;
    const bigAppetitePizaPct = .75;

    // Square inches of pizza each appetite needs
    const smallAppetiteTotal = numSmallAppetites * (twelveInPizzaAreaSqInches * smallAppetitePizzaPct);
    const medAppetiteTotal = numMedAppetites * (twelveInPizzaAreaSqInches * medAppetitePizzaPct);
    const bigAppetiteTotal = numBigAppetites * (twelveInPizzaAreaSqInches * bigAppetitePizaPct);
    const totalPizzaAreaNeeded = smallAppetiteTotal + medAppetiteTotal + bigAppetiteTotal;

    const onePizzaAreaSqInches = 3.14 * (pizzaDiameter / 2) * (pizzaDiameter / 2);
    return onePizzaAreaSqInches === 0 ? 0 : ceil(totalPizzaAreaNeeded / onePizzaAreaSqInches);
  }

  function getPizzasNeededLabel(numPizzas) {
    const label = numPizzas === 1 ? "pizza" : "pizzas";
    return " " + label + " needed";
  }

  return (
    <div>
      <div>
        <Container>
          <Row><h1>Appetites</h1></Row>
          <Row>
            <Col>
              <PizzaIcon />
              <Form.Group controlId="smallAppetiteInput">
                <div className="appetite-label">
                  <Form.Label>small</Form.Label>
                </div>
                <Form.Control type="number" value={numSmallAppetites} min={0} onChange={e => setSmallAppetites(e.target.value)} />
              </Form.Group>
            </Col>
            <Col>
              <PizzaIcon /> <PizzaIcon />
              <Form.Group controlId="medAppetiteInput">
                <div className="appetite-label">
                  <Form.Label>medium</Form.Label>
                </div>
                <Form.Control type="number" value={numMedAppetites} min={0} onChange={e => setMedAppetites(e.target.value)} />
              </Form.Group>
            </Col>
            <Col>
              <PizzaIcon /> <PizzaIcon /> <PizzaIcon />
              <Form.Group controlId="bigAppetiteInput">
                <div className="appetite-label">
                  <Form.Label>big</Form.Label>
                </div>
                <Form.Control type="number" value={numBigAppetites} min={0} onChange={e => setBigAppetites(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row><h1>Pizza Diameter (in)</h1></Row>
          <Row>
            <Col>
              <Form.Group controlId="pizzaDiameterInput">
                <Form.Control className="form-option" type="number" value={pizzaDiameter} min={0} onChange={e => setPizzaDiameter(e.target.value)} />
              </Form.Group>
            </Col>
            <Col xs={2} className="pizza-diameter-separator">or</Col>
            <Col>
              <Form.Select onChange={e => setPizzaDiameter(e.target.value)} className="form-option">
                <option value="12">Doppio Zero (12")</option>
                <option value="16">Bellissimo XL (16")</option>
                <option value="16">Primo (16")</option>
                <option value="12">Slice House (12")</option>
                <option value="20">Slice House XL (20")</option>
              </Form.Select>
            </Col>
          </Row>
        </Container>
      </div>

      <br />
      <Container>
        <h1><span className="pizza-answer">{calculateNecessaryPizzas()} {getPizzasNeededLabel(calculateNecessaryPizzas())}</span></h1>
      </Container>

      <br /><br />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={pizza} className="App-logo" alt="pizza" />

        <br /><br /><br />
        PIZZA MATH <br />
        BY JAMES MANSON
        <br /><br />
        ↓
      </header>

      <Math />

      <div className="footer"></div>
    </div>
  );
}

export default App;
